import { Fragment, useId } from 'react'

import { GRID, GRID_ITEM_FULL_WIDTH, routes } from '@/lib/constants'
import {
  LandingPageSmallStoryCardsCollection,
  WerkenBijPage,
  WerkenBijPageSmallStoryCardsCollection,
} from '@/interfaces/schema'
import Section from '@/layouts/section'
import Block from '@/layouts/block'
import { StoryCardWithImgPosition } from '@/interfaces/stories'
import StoryCardWithImage from '@/components/cards/stories/with-image'
import { H2 } from '@/components/typography'
import { FolderIcon } from '@/components/icons/folder'

type Props = Pick<WerkenBijPage, 'firstBigStoryCard' | 'secondBigStoryCard'> & {
  smallStoryCardsCollection?:
    | WerkenBijPageSmallStoryCardsCollection
    | LandingPageSmallStoryCardsCollection
}

export default function StoriesSection({
  firstBigStoryCard,
  secondBigStoryCard,
  smallStoryCardsCollection,
}: Props) {
  const id = useId()
  const firstStoryCardFullUrl = `${routes.rvdb.knowledgeAndInspiration}/${firstBigStoryCard?.slug}`

  return (
    <Section
      className={`${GRID} gap-2 lg:gap-12 w-full mb-[30px] lg:mb-[120px]`}
    >
      <Block className={`${GRID_ITEM_FULL_WIDTH} flex gap-4 items-center`}>
        <FolderIcon />
        <H2>Enkele verhalen</H2>
      </Block>
      <StoryCardWithImage
        image={firstBigStoryCard?.image}
        title={firstBigStoryCard?.title}
        description={firstBigStoryCard?.description}
        // TODO replace date with render props to render custom content
        publicationDate={firstBigStoryCard?.publicationDate}
        buttonLink={firstStoryCardFullUrl}
        imgPosition={StoryCardWithImgPosition.LEFT}
        className={`${GRID_ITEM_FULL_WIDTH}`}
      />
      <Block className={`${GRID_ITEM_FULL_WIDTH} ${GRID}`}>
        {smallStoryCardsCollection?.items.map(
          ({ title, description, image, slug, publicationDate }, i) => (
            <Fragment key={`${id}_title_${i}`}>
              <StoryCardWithImage
                className="col-span-2 md:col-span-3 lg:col-span-4"
                image={image}
                title={title}
                description={description}
                publicationDate={publicationDate}
                buttonLink={`${routes.rvdb.knowledgeAndInspiration}/${slug}`}
                imgPosition={StoryCardWithImgPosition.TOP}
              />
            </Fragment>
          )
        )}
      </Block>
      <StoryCardWithImage
        // TODO replace the image with render props
        image={secondBigStoryCard?.image}
        title={secondBigStoryCard?.title}
        description={secondBigStoryCard?.description}
        publicationDate={secondBigStoryCard?.publicationDate}
        buttonLink={secondBigStoryCard?.slug}
        imgPosition={StoryCardWithImgPosition.RIGHT}
        className={`${GRID_ITEM_FULL_WIDTH} mt-0 lg:mt-16`}
      />
    </Section>
  )
}
