export const FolderIcon = ({ width = '40', height = '36', className = '' }) => {
  return (
    <span className={`${className}`}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 40 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35 5.5H21.4844L17.6562 1.98438C16.7188 1.04688 15.4688 0.5 14.1406 0.5H5C2.1875 0.5 0 2.76562 0 5.5V30.5C0 33.3125 2.1875 35.5 5 35.5H35C37.7344 35.5 40 33.3125 40 30.5V10.5C40 7.76562 37.7344 5.5 35 5.5ZM36.25 30.5C36.25 31.2031 35.625 31.75 35 31.75H5C4.29688 31.75 3.75 31.2031 3.75 30.5V5.5C3.75 4.875 4.29688 4.25 5 4.25H14.1406C14.4531 4.25 14.7656 4.40625 15 4.64062L20 9.25H35C35.625 9.25 36.25 9.875 36.25 10.5V30.5ZM12.5 16.75H7.5C6.79688 16.75 6.25 17.375 6.25 18V23C6.25 23.7031 6.79688 24.25 7.5 24.25H12.5C13.125 24.25 13.75 23.7031 13.75 23V18C13.75 17.375 13.125 16.75 12.5 16.75ZM22.5 16.75H17.5C16.7969 16.75 16.25 17.375 16.25 18V23C16.25 23.7031 16.7969 24.25 17.5 24.25H22.5C23.125 24.25 23.75 23.7031 23.75 23V18C23.75 17.375 23.125 16.75 22.5 16.75ZM32.5 16.75H27.5C26.7969 16.75 26.25 17.375 26.25 18V23C26.25 23.7031 26.7969 24.25 27.5 24.25H32.5C33.125 24.25 33.75 23.7031 33.75 23V18C33.75 17.375 33.125 16.75 32.5 16.75Z"
          fill="#464646"
        />
      </svg>
    </span>
  )
}
