import RichTextRenderer from '@/services/contentful/utils/richText'
import { RichTextProps } from '@/interfaces/richText'

interface Props {
  className?: string
  contactFormCode?: RichTextProps
}

export default function ContactForm({
  className = '',
  contactFormCode,
}: Props) {
  return (
    <RichTextRenderer
      className={`contactForm ${className}`}
      richText={contactFormCode}
    />
  )
}
