import { GetStaticPaths, GetStaticPropsContext } from 'next'

import PageProps from '@/interfaces/page'
import Main from '@/layouts/main'
import {
  getLandingPageData,
  getLandingPageSlugData,
  getRvdbNavigationData,
} from '@/services/contentful/api'
import { LandingPage } from '@/interfaces/schema'
import TextBlocks from '@/sections/rvdb/werken-bij/text-blocks'
import JobsSection from '@/sections/rvdb/werken-bij/jobs'
import StoriesSection from '@/sections/rvdb/werken-bij/stories'
import OverRaySection from '@/sections/rvdb/werken-bij/over-ray'
import ClientsSection from '@/sections/repeatable/clients'
import VacanciesAndCtaSection from '@/sections/repeatable/vacancies-and-cta/three-cards'
import { getSlugs, stringifySlug } from '@/lib/slugs'
import { getPaths } from '@/lib/paths'
import { BannerMedium } from '@/sections/repeatable'
import OrganizationCardsSection from '@/sections/rvdb/organization-cards'
import DetailCTAFormSection from '@/sections/rvdb/detail-cta-form'
import ReviewSection from '@/sections/repeatable/review'

const GenericLandingPage = ({ data }: PageProps<LandingPage>) => {
  const {
    bannerTitle,
    bannerSubtitle,
    bannerImage,
    displayFirstTextSection,
    firstTextSectionTitle,
    firstTextSectionContent,
    displaySecondTextSection,
    secondTextSectionTitle,
    secondTextSectionContent,
    displayFirstConsultancyCardsSection,
    firstConsultancyCardsSectionIcon,
    firstConsultancyCardsSectionTitle,
    firstConsultancyCardsSectionButtonUrl,
    firstConsultancyCardsListCollection,
    displaySecondConsultancyCardsSection,
    secondConsultancyCardsSectionIcon,
    secondConsultancyCardsSectionTitle,
    secondConsultancyCardsSectionButtonUrl,
    secondConsultancyCardsListCollection,
    displayJobsSection,
    jobsSectionImage,
    ctaJobCardsCollection,
    displayVacanciesSection,
    vacanciesSectionTitle,
    vacanciesSectionIcon,
    vacancyIDsCollection,
    ctaVacanciesCard,
    displayStoriesSection,
    firstBigStoryCard,
    smallStoryCardsCollection,
    secondBigStoryCard,
    displayOverRayCard,
    overRayCard,
    displayCallToActionSection,
    callToActionSectionId,
    callToActionSectionIcon,
    callToActionSectionTitle,
    callToActionSectionBody,
    contactFormCode,
    displayClientsSection,
    clientsSectionIcon,
    clientsSectionTitle,
    clientsList,
    displayReviewSection,
    reviewSectionIcon,
    reviewSectionTitle,
    testimonialsCollection,
  } = data

  return (
    <Main>
      <BannerMedium
        bannerTitle={bannerTitle}
        bannerSubtitle={bannerSubtitle}
        bannerImage={bannerImage}
      />
      {(displayFirstTextSection || displaySecondTextSection) && (
        <TextBlocks
          displayFirstTextBlock={displayFirstTextSection}
          firstTextBlockTitle={firstTextSectionTitle}
          firstTextBlockContent={firstTextSectionContent}
          displaySecondTextBlock={displaySecondTextSection}
          secondTextBlockTitle={secondTextSectionTitle}
          secondTextBlockContent={secondTextSectionContent}
        />
      )}
      {displayFirstConsultancyCardsSection && (
        <OrganizationCardsSection
          isConsultancy
          icon={firstConsultancyCardsSectionIcon}
          title={firstConsultancyCardsSectionTitle}
          cardsCollection={firstConsultancyCardsListCollection}
          showAllButtonUrl={firstConsultancyCardsSectionButtonUrl}
        />
      )}
      {displaySecondConsultancyCardsSection && (
        <OrganizationCardsSection
          isConsultancy
          icon={secondConsultancyCardsSectionIcon}
          title={secondConsultancyCardsSectionTitle}
          cardsCollection={secondConsultancyCardsListCollection}
          showAllButtonUrl={secondConsultancyCardsSectionButtonUrl}
        />
      )}
      {displayJobsSection && (
        <JobsSection
          jobsSectionMediaAsset={jobsSectionImage}
          ctaJobCardsCollection={ctaJobCardsCollection}
        />
      )}
      {displayVacanciesSection && (
        <VacanciesAndCtaSection
          vacanciesSectionTitle={vacanciesSectionTitle}
          vacanciesSectionIcon={vacanciesSectionIcon}
          ctaVacanciesCard={ctaVacanciesCard}
          vacancyIdCollection={vacancyIDsCollection}
        />
      )}
      {displayStoriesSection && (
        <StoriesSection
          firstBigStoryCard={firstBigStoryCard}
          secondBigStoryCard={secondBigStoryCard}
          smallStoryCardsCollection={smallStoryCardsCollection}
        />
      )}
      {displayOverRayCard && <OverRaySection overRayCard={overRayCard} />}
      {displayCallToActionSection && (
        <DetailCTAFormSection
          callToActionSectionIcon={callToActionSectionIcon}
          callToActionSectionTitle={callToActionSectionTitle}
          callToActionSectionBody={callToActionSectionBody}
          contactFormCode={contactFormCode}
          id={callToActionSectionId}
        />
      )}
      {displayClientsSection && (
        <ClientsSection
          clientsSectionIcon={clientsSectionIcon}
          clientsSectionTitle={clientsSectionTitle}
          clientsList={clientsList}
        />
      )}
      {displayReviewSection && (
        <ReviewSection
          reviewSectionIcon={reviewSectionIcon}
          reviewSectionTitle={reviewSectionTitle}
          testimonialsCollection={testimonialsCollection}
        />
      )}
    </Main>
  )
}

export async function getStaticProps({
  params,
  preview,
}: GetStaticPropsContext) {
  const isPreview: boolean = !!preview ?? false
  const slug = stringifySlug(params)

  const { data: pageData, error: pageError } = await getLandingPageData(
    isPreview,
    slug
  )
  const { data: navData, error: navError } = await getRvdbNavigationData(
    isPreview
  )

  return {
    props: {
      data: { navData, pageData: pageData?.items[0] },
      error: { pageError, navError },
    },
    revalidate: 60,
  }
}

export const getStaticPaths: GetStaticPaths = async () => {
  const isPreview = false
  const { data } = await getLandingPageSlugData(isPreview)
  const slugs = getSlugs(data)
  const paths = getPaths(slugs)

  return {
    paths,
    fallback: true,
  }
}

export default GenericLandingPage
