import RichTextRenderer from '@/services/contentful/utils/richText'
import { GRID, GRID_ITEM_FULL_WIDTH } from '@/lib/constants'
import { Scalars, WerkenBijPage } from '@/interfaces/schema'
import { H2 } from '@/components/typography'
import Section from '@/layouts/section'
import Block from '@/layouts/block'

type Props = Pick<
  WerkenBijPage,
  | 'displayFirstTextBlock'
  | 'firstTextBlockTitle'
  | 'displaySecondTextBlock'
  | 'secondTextBlockTitle'
> & {
  secondTextBlockContent?: { json: Scalars['JSON'] }
  firstTextBlockContent?: { json: Scalars['JSON'] }
}

export default function TextBlocks({
  displayFirstTextBlock,
  firstTextBlockTitle,
  firstTextBlockContent,
  displaySecondTextBlock,
  secondTextBlockTitle,
  secondTextBlockContent,
}: Props) {
  return (
    <Section className="flex flex-col gap-14 w-full mb-[30px] lg:mb-[120px]">
      {displayFirstTextBlock && (
        <Block className={`${GRID} ${GRID_ITEM_FULL_WIDTH}`}>
          <H2 className="col-span-2 md:col-start-1 md:col-end-3 lg:col-start-1 lg:col-end-5">
            {firstTextBlockTitle}
          </H2>
          <div className="col-span-2 md:col-start-3 md:col-end-7 lg:col-start-5 lg:col-end-13">
            <RichTextRenderer richText={firstTextBlockContent} />
          </div>
        </Block>
      )}
      {displaySecondTextBlock && (
        <Block className={`${GRID} ${GRID_ITEM_FULL_WIDTH}`}>
          <H2 className="col-span-2 md:col-start-1 md:col-end-3 lg:col-start-1 lg:col-end-5">
            {secondTextBlockTitle}
          </H2>
          <div
            className={`col-span-2 md:col-start-3 md:col-end-7 lg:col-start-5 lg:col-end-13`}
          >
            <RichTextRenderer richText={secondTextBlockContent} />
          </div>
        </Block>
      )}
    </Section>
  )
}
