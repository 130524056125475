import OverRayCard from '@/components/cards/cta/over-ray'
import { WerkenBijPage } from '@/interfaces/schema'
import Section from '@/layouts/section'
import { GRID, GRID_ITEM_FULL_WIDTH } from '@/lib/constants'

type Props = Pick<WerkenBijPage, 'overRayCard' | 'displayOverRayCard'>

export default function OverRaySection({ overRayCard }: Props) {
  return (
    <Section className={`${GRID} mb-[30px] lg:mb-[120px]`}>
      <OverRayCard
        title={overRayCard?.title ?? ''}
        description={overRayCard?.description}
        mediaAsset={overRayCard?.mediaAsset}
        registerButtonText={overRayCard?.registerButtonText}
        loginButtonText={overRayCard?.loginButtonText}
        className={`${GRID_ITEM_FULL_WIDTH}`}
      />
    </Section>
  )
}
