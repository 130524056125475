import ContactForm from '@/blocks/rvdb/contact-form'
import IconWithTitle from '@/components/icon-with-title'
import { RichTextProps } from '@/interfaces/richText'
import { Asset } from '@/interfaces/schema'
import Block from '@/layouts/block'
import Section from '@/layouts/section'
import { GRID } from '@/lib/constants'
import RichTextRenderer from '@/services/contentful/utils/richText'

interface Props {
  callToActionSectionIcon?: Asset
  callToActionSectionTitle?: string
  callToActionSectionBody?: RichTextProps
  contactFormCode?: RichTextProps
  id?: string
}

const DetailCTAFormSection = ({
  callToActionSectionIcon,
  callToActionSectionTitle,
  callToActionSectionBody,
  contactFormCode,
  id = '',
}: Props) => {
  return (
    <Section id={id} className={`${GRID} mb-[30px] lg:mb-[120px]`}>
      <IconWithTitle
        className="lg:col-start-1 lg:col-end-5"
        icon={callToActionSectionIcon}
        title={callToActionSectionTitle}
      />
      <Block className="flex flex-col col-span-full lg:col-start-5 lg:col-end-13">
        <RichTextRenderer richText={callToActionSectionBody} />
        <ContactForm className="mt-[50px]" contactFormCode={contactFormCode} />
      </Block>
    </Section>
  )
}

export default DetailCTAFormSection
