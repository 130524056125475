import React from 'react'

import Block from '@/layouts/block'
import Image from '@/components/image'
import { Q1 } from '@/components/typography'
import {
  ButtonLinkPrimaryTextAndArrow,
  ButtonLinkText,
} from '@/components/button'
import { OverRayCard as OverRayCardProps } from '@/interfaces/schema'
import RichTextRenderer from '@/services/contentful/utils/richText'
import { routes } from '@/lib/constants'

type Props = Omit<OverRayCardProps, 'Entry' | 'sys' | 'contentfulMetadata' | '_id'> & {
  className?: string
}

export default function OverRayCard({
  title,
  description,
  mediaAsset,
  loginButtonText,
  registerButtonText,
  className = '',
}: Props) {
  return (
    <Block className={`storyCardWithImg storyCardWithImg--left ${className}`}>
      <Image
        className="storyCardWithImg--left__img"
        src={mediaAsset?.url ?? ''}
        alt="profile-card"
        layout="fill"
        width={mediaAsset?.width}
        height={mediaAsset?.height}
      />
      <Block className="storyCardWithImg__text">
        <Q1 className="!text-ray-secondary">{title}</Q1>
        <RichTextRenderer richText={description} />
        <div className="flex flex-col sm:flex-row gap-4 justify-between">
          <ButtonLinkPrimaryTextAndArrow href={routes.rvdb.auth.signup}>
            {registerButtonText}
          </ButtonLinkPrimaryTextAndArrow>
          <ButtonLinkText href={routes.rvdb.auth.login}>
            {loginButtonText}
          </ButtonLinkText>
        </div>
      </Block>
    </Block>
  )
}
