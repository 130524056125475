import { Fragment, useId } from 'react'

import {
  LandingPageCtaJobCardsCollection,
  WerkenBijPage,
  WerkenBijPageCtaJobCardsCollection,
} from '@/interfaces/schema'
import Section from '@/layouts/section'
import Image from '@/components/image'
import JobsCard from '@/components/cards/services/jobs'
import Block from '@/layouts/block'
import { GRID, GRID_ITEM_FULL_WIDTH } from '@/lib/constants'
import { truncateText } from '@/lib/string'

type Props = Pick<WerkenBijPage, 'jobsSectionMediaAsset'> & {
  ctaJobCardsCollection?:
    | WerkenBijPageCtaJobCardsCollection
    | LandingPageCtaJobCardsCollection
}

export default function JobsSection({
  jobsSectionMediaAsset,
  ctaJobCardsCollection,
}: Props) {
  const id = useId()

  return (
    <Section
      className={`${GRID} place-items-center relative md:h-[440px] w-full !max-w-[1360px] mb-[965px] md:mb-[1250px] lg:mb-[430px] xl:mb-[360px]`}
    >
      <Block className={`${GRID_ITEM_FULL_WIDTH} werkenBijJobsBanner`}>
        <Image
          objectPosition="top"
          src={jobsSectionMediaAsset?.url ?? ''}
          width={jobsSectionMediaAsset?.width}
          height={jobsSectionMediaAsset?.height}
          alt={jobsSectionMediaAsset?.description}
        />
      </Block>
      <Block className="absolute bottom-unset lg:bottom-[-42%] top-[75%] lg:top-unset flex flex-col lg:flex-row gap-3 lg:gap-10 items-center justify-center">
        {ctaJobCardsCollection?.items.map(
          ({ title, description, buttonText, buttonPath }, i) => (
            <Fragment key={`${id}_title_${i}`}>
              <JobsCard
                title={title ?? ''}
                description={truncateText(description ?? '', 120)}
                buttonText={buttonText ?? 'Lees meer'}
                buttonPath={buttonPath}
              />
            </Fragment>
          )
        )}
      </Block>
    </Section>
  )
}
